import { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Modal,
  Row,
  Form,
  CloseButton,
} from "react-bootstrap";
import { openAiService } from "../../services/openai.services";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_PLAN_ID,
  PREMIUM_PLAN_ID,
  sampleQuestions,
  prompts,
  Project_Title,
} from "../../config/config";
import Swal from "sweetalert2";
import { useContext } from "react";
import { context } from "../../GlobalContext/GlobalContext";
import { capitalizeFirstLetter } from "../utils/localStorage";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useCallback } from "react";
import { baseUrl, EMAILREGEX, allDEPARTMENT } from "../../config/config";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Datetime from "react-datetime";
import moment from "moment";
import { Loading } from "../common/loader";
import DatePicker from "react-datepicker";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { isClickableInput } from "@testing-library/user-event/dist/utils";
import { CommonFunction } from "../common/functions";
const ct = require("countries-and-timezones");
const minDate = new Date();
const current = new Date();
minDate.setDate(minDate.getDate() - 1);
const currentDateToSelect = new Date(
  current.setTime(current.getTime() + 1 * 60 * 60 * 1000)
);
const timezone = ct.getTimezone(
  Intl.DateTimeFormat().resolvedOptions().timeZone
);
const COUNTRY_CODE = timezone.countries[0];

var allDays = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

const Chat = () => {
  const userId = JSON.parse(localStorage.getItem("user"))?.user_id;
  localStorage.removeItem("navigate");
  localStorage.removeItem('redirectUrl');
  const navigate = useNavigate();
  const globalData = useContext(context);
  const globalDataRef = useRef();
  globalDataRef.current = globalData;
  const [departmentval, setdepartment] = useState();
  const URL = `wss://api.aiattorney.biz/ws/chat/${userId}`;
  const { sendMessage, lastMessage, readyState } = useWebSocket(URL);
  const [show, setShow] = useState(false);
  const [queryString, setQueryString] = useState("");
  const [chat, setChat] = useState([]);
  // const [selectfiles, setselectFiles] = useState([]);
  const [selectfile, setselectFile] = useState();
  const [selectfileName, setselectFileName] = useState();
  const [suggesteduserId, setSuggesteduserId] = useState();
  const [chatResponse, setChatResponse] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [openaiId, setOpenaiId] = useState("");
  const [isBookmarked, setIsBookmarked] = useState(0);
  const [history, setHistory] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [showChatResponse, setshowChatResponse] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [chatTriggered, setChatTriggered] = useState(false);
  const [trigerScroll, setTriggerScroll] = useState(false);
  const [elementHeight, setElementHeight] = useState();
  const [pdfFile, setPdfFile] = useState();
  const [pdfFile0, setPdfFile0] = useState();
  const [pdfFile1, setPdfFile1] = useState();
  const [showPdfPrompt, setShowPdfPrompt] = useState(false);
  const [showComparePdfPrompt, setCompareShowPdfPrompt] = useState(false);
  const [upGradePlanPopupModal, setUpgradePlanPopupModal] = useState(false);
  const [chatCount, setChatCount] = useState(0);
  const [keepScrollingToBottom, setKeepScrollingToBottom] = useState(false);
  const phoneno = /^\d{10}$/;
  let bookmarkDisabled = true;
  const generatedAnswer = useRef("");
  const [isCaptchaValue, setIsCaptchaValue] = useState();
  const [isUserDobExists, setIsUserDobExists] = useState();
  const [requestFor, setRequestFor] = useState([]);
  const [showBoookmark, setShowBoookmark] = useState(false);
  const [providers, setProviders] = useState([]);
  const [userSuggestion, setUserSuggestion] = useState([]);
  const selected = useRef("");
  const [isLoader, setIsLoader] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [index, setIndex] = useState();
  const [enableDays, setEnableDays] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [recommendedSlots, setRecommendedSlots] = useState([]);
  const [consine, setConsine] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");
  const [reason, setReason] = useState("");
  const [isExtra, setIsExtra] = useState(false);
  const [providerId, setProviderId] = useState("");
  const [isSeemar, setIsSeemar] = useState(false);
  const [appointmentFields, setAppointmentFields] = useState({
    // firstName: "",
    reason: "",
    // lastName: "",
    // phone: "",
    // email: "",
    // message: "",
    insurance_type: "self",
    insurance_type_text: "",
    appointment_for: "me",
    patient_gender: "",
    patient_name: "",
    patient_age: "",
    phone: "",
    selectedDate: new Date(),
    request_for: "",
    report_files: [],
    slot: "",
  });

  const [consentShow, setConsentShow] = useState(false);
  const [allowTreatment, setAllowTreatment] = useState(false);
  const [allowRisk, setAllowRisk] = useState(false);
  const [infoAccurate, setInfoAccurate] = useState(false);
  const [allowHealthData, setAllowHealthData] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [onMobileClick, setOnMobileClick] = useState(false);
  const [buttonText, setButtonText] = useState(require("../../assests/images/menu-icon.svg").default);
  const [appointmentFieldsErrors, setAppointmentFieldsErrors] = useState({
    //  firstName: false,
    //  reason:false,
    //  lastName: false,
    //  phone: false,
    //  email: false,
    //  message: false,

    reason: false,
    insurance_type: false,
    insurance_type_text: false,
    appointment_for: false,
    patient_gender: false,
    patient_name: false,
    patient_age: false,
    phone: false,
    selectedDate: false,
    request_for: false,
    report_files: false,
    slot: false,
  });
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const microphoneRef = useRef(null);
  const [counterHtml, setcountHtml] = useState("");
  const interval = useRef(null);
  const [memberSelected,setMemberSelected] = useState("")
  const input_focused = useRef();
  // const [isActive, setIsActive] = useState("me")
  // const [showAdd, setShowAdd] = useState(false)
  // const [members, setMembers] = useState([]);
  // const [memberName, setMemberName] = useState("")

  // const handleCloseAdd = () => setShowAdd(false);
  // const handleShowAdd = () => setShowAdd(true);
  // const [isCaptchaValue, setIsCaptchaValue] = useState();
  function onChange(value) {
    // isCaptchaValue.current = value;
    setIsCaptchaValue(value);
    console.log("Captcha value:", value);
  }

  useEffect(() => {
    init();
    getAvailableDays();
    // getFamilyMember();
    getAvailableSlots(moment(new Date()).format("YYYY-MM-DD"));
  }, []);
  // socket connection
  useEffect(() => {
    if (lastMessage !== null) {
      console.log("lastmassage--", readyState, JSON.parse(lastMessage.data));
      setShowLoader(false);
      let messageResponse = JSON.parse(lastMessage.data);
      if (!messageResponse.stream) {
        setshowChatResponse(false);
        setChatTriggered(false);
        let answer = generatedAnswer.current;
        setChat((prevObj) => [...prevObj, { type: "answer", text: answer }]);
        generatedAnswer.current = "";
        let elm = document.getElementById("chat-response-id");
        if (elm) {
          elm.innerHTML = "";
        }
        if (localStorage.getItem("plan")) {
          // getChat(messageResponse.openai_id)
        }
      } else {
        console.log("setopen---- 11111", messageResponse.openai_id);
        setOpenaiId(messageResponse.openai_id);
        let elm = document.getElementById("chat-response-id");
        if (elm) {
          elm.innerHTML = messageResponse.message;
        }
        generatedAnswer.current = messageResponse.message;
        if (
          element.scrollHeight - (element.scrollTop + element.clientHeight) >
          200
        ) {
          setKeepScrollingToBottom(false);
        } else {
          setKeepScrollingToBottom(true);
        }
        setElementHeight(element.scrollHeight);
      }
    }
  }, [lastMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];
  console.log("connection status-", connectionStatus);

  const [pageNo, setPageNo] = useState(1);
  const [totalHistoryRecords, setTotalHistoryRecords] = useState(0);
  const [allFiles, setFiles] = useState();
  // const [bookmarkDisabled, setBookmarkDisabled] = useState(true);
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    getProfiles();
    getFiles();
    getRequestFor();
  }, [pageNo]);
  // when question response is recieved this useffect is triggered to start typing animation effect
  useEffect(() => {
    if (chatTriggered) {
      setTimeout(() => {
        typeWriterEffect();
      }, 300);
    }
  }, [chatResponse]);
  const selectfileData = (id, name) => {
    setRecommendedSlots([]);
    setIsExtra(false);
    setselectFile(id);
    setselectFileName(name);
    // document.getElementById('file_'+id).classList.add('selected')
    getChat(id);
  };
  useEffect(() => {
    setQueryString(transcript);
    if(isListening){
      document.getElementById("input_focused").focus();
      const textInputEl = input_focused.current;
      textInputEl.scrollTop = textInputEl.scrollHeight;
    }
  }, [transcript]);
  const element = document.getElementById("chatbox-body-id");
  const leftChatElement = document.getElementById("left-chat-boxes-id");
  // History api call when scrolled to bottom.
  leftChatElement?.addEventListener("scroll", onBottomScroll, false);

  function onBottomScroll() {
    if (
      leftChatElement?.scrollHeight ===
      leftChatElement?.scrollTop + leftChatElement?.clientHeight
    ) {
      if (totalHistoryRecords > history.length) {
        setPageNo(pageNo + 1);
      }
    }
  }

  function getProfiles() {
    openAiService
      .getProfiles()
      .then((res) => {
        setPhoneNo(res.data?.data.phone ? res.data?.data.phone : "");
        console.log("result-------", res.data?.data.dob);
        setIsUserDobExists(res.data?.data.dob);
      })
      .catch((error) => {
        console.log("userdetails error------", error);
      });
  }

  // function getFamilyMember() {
  //   openAiService.getFamilyMembers()
  //     .then((res) => {
  //       setMembers(res.data?.data);
  //       console.log("result", res.data?.data)
  //     })
  //     .catch((err) => console.log("err--", err))
  // }

  const hideConsent = () => {
    setConsentShow(false);
    setIsChecked(false);
  };

  const handleChange = (event) => {
    if (event.target.checked) {
      setConsentShow(true);
    } else {
      setAllowTreatment(false);
      setAllowRisk(false);
      setInfoAccurate(false);
      setAllowHealthData(false);
      setConsentShow(false);
    }
    setIsChecked((current) => !current);
  };

  function getAvailableDays() {
    const dayArr = [];
    openAiService
      .getAvailableDays()
      .then((result) => {
        console.log("availbale dayass", result.data.data);
        result.data.data
          .filter((d) => d.enabled)
          .map((res, index) => {
            console.log("available slots", res.day);
            // let found = allDays.indexOf("Apple");

            dayArr.push(
              allDays.indexOf(res.day) !== -1 ? allDays.indexOf(res.day) : ""
            );
            console.log("dayARRRRRRRR---", res.day, dayArr);
            setEnableDays(dayArr);
            // if(!enableDays.includes(res.day)){
            //   enableDays.push(res.day)
            // }
          });
      })
      .catch((err) => console.log("error--", err));
  }

  console.log("available slots-----", enableDays);

  // keeps the chat box scrolled down to bottom
  useEffect(() => {
    if (keepScrollingToBottom) {
      element?.scrollTo({
        top: element.scrollHeight,
        behavior: "smooth",
      });
    }
    getProviders();
    getUserSuggestions();
  }, [element?.scrollHeight, elementHeight, trigerScroll]);

  function init() {
    bookmarkDisabled = localStorage.getItem("plan") === null;
    console.log("bookmarkDisabled", bookmarkDisabled);
    getSubscription();
    listPlans();
  }

  const getUserSuggestions = () => {
    openAiService
      .getUserSuggestions()
      .then((res) => {
        console.log("getUserSuggestions", res.data?.data);
        setUserSuggestion(res.data?.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  function getFiles() {
    openAiService
      .getFiles(pageNo)
      .then((res) => {
        console.log("all files", res.data?.data[0].id);
        setFiles(res.data?.data);
        // setIsActive(res.data?.data[0]?.member ? "someone" : "me")
        // setMemberSelected(res.data?.data[0]?.member ? res.data?.data[0]?.member : "")
      
          selectfileData(res.data?.data[0].id, res.data?.data[0].file);
  

        // setTotalHistoryRecords(res.data?.totalCount)
        // setChatCount(res.data.history)
        let fileArray = res.data?.data;
        let bookmarkArray = res.data?.data.filter(
          (obj) => obj.is_bookmarked === true
        );
        if (fileArray.length > 0) {
          setFiles([...fileArray]);
          setBookmarks([...bookmarkArray]);
        } else {
          setPageNo(pageNo > 0 ? pageNo - 1 : 0);
        }
        // setHistoryLoading(false)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          // alert("===")
          globalDataRef.current.setSignUpModal(true);
          // navigate("/")
        }
      });
  }

  function getAvailableSlots(date) {
    openAiService
      .getAvailableSlots(date)
      .then((result) => {
        //  console.log("getAvailableSlots------", new Date(date +" "+result.data.data))
        var output = result?.data?.data.filter(
          (s) => new Date(date + " " + s.time)?.getTime() > new Date().getTime()
        );
        setAvailableSlots(output);
      })
      .catch((err) => console.log(err));
  }

  // to get the chats of history(or bookmark) when any history is clicked
  function getChat(openaiId) {
    setShowLoader(false);
    setshowChatResponse(false);
    console.log("setopen---- 22222", openaiId);
    setOpenaiId(openaiId);
    setChat([]);
    openAiService
      .getDocumentChat(openaiId)
      .then((res) => {
        setShowBoookmark(false);
        console.log("chat reponse----of the report", res.data);
        let history = res.data?.data;
        // let chlidrenLength = res.data.children.length;
        // setMemberSelected(res.data?.member ? res.data?.member :"")
        // setIsActive(res.data?.member ? "someone" :"me")  
        setReason(history.prompt);
        var element = document.getElementById("chatbox-body-id");
        if (
          element.scrollHeight - (element.scrollTop + element.clientHeight) >
          200
        ) {
          setKeepScrollingToBottom(false);
        } else {
          setKeepScrollingToBottom(true);
        }
        console.log(res, "==history=");
        setChat([{ type: "question", text: history.prompt },{ type: "answer", text: history.response }])
        // setChat((prevObj) => [
        //   ...prevObj,
        //   { type: "question", text: history.prompt },
        // ]);
        // setChat((prevObj) => [
        //   ...prevObj,
        //   { type: "answer", text: history.response },
        // ]);
        setIsBookmarked(history.is_bookmarked ? 1 : 0);
        let children = res.data.children;
        children?.map((historyObj) => {
          console.log("istory child", historyObj)
          setChat((prevObj) => [
            ...prevObj,
            { type: "question", text: historyObj.prompt },
          ]);
          setChat((prevObj) => [
            ...prevObj,
            { type: "answer", text: historyObj.response },
          ]);
          return null;
        });
        setTriggerScroll(!trigerScroll);
      })
      .catch((error) => {
        setShowBoookmark(true);
        if (error?.response?.status === 404) {
          setChat([]);
          // setMemberSelected("")
          // setIsActive("me")  
        }
        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        }
      });
  }

  const getProviders = () => {
    openAiService
      .getProviders()
      .then((res) => {
        setProviders(res.data?.data);
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          setChat([]);
        } else if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  // typing effect function call
  var i = 0;
  var speed = 30;
  function typeWriterEffect() {
    if (i < chatResponse.length) {
      let elm = document.getElementById("chat-response-id");
      if (elm) {
        elm.innerHTML += chatResponse.charAt(i);
      }
      if (
        element.scrollHeight - (element.scrollTop + element.clientHeight) >
        200
      ) {
        setKeepScrollingToBottom(false);
      } else {
        setKeepScrollingToBottom(true);
      }
      setElementHeight(element.scrollHeight);
      i++;
      setTriggerScroll(!trigerScroll);
      setTimeout(typeWriterEffect, speed);
    } else {
      setshowChatResponse(false);
      setChatTriggered(false);
      setChat((prevObj) => [
        ...prevObj,
        { type: "answer", text: chatResponse },
      ]);
      setChatResponse("");
    }
  }

  const handleSendMessage = useCallback((data) => sendMessage(data), []);

  function removeSecondFromString(time) {
    var modify = time.split(":");
    return modify[0] + ":" + modify[1];
  }

  const askQuestion = (query) => {
    console.log("===selectfile=", selectfile);
    setShowBoookmark(false);
    if (selectfile) {
      // setShowBoookmark(false);
      setQueryString("");
      resetTranscript();

      var element = document.getElementById("chatbox-body-id");
      if (
        element.scrollHeight - (element.scrollTop + element.clientHeight) >
        200
      ) {
        setKeepScrollingToBottom(false);
      } else {
        setKeepScrollingToBottom(true);
      }
      setChat((prevObj) => [...prevObj, { type: "question", text: query }]);
      setTriggerScroll(!trigerScroll);
      setShowLoader(true);
      setChatTriggered(true);
      let data = {
        openai_id: openaiId,
        prompt: query,
        selectfiles: selectfile,
      };
      openAiService
        .comparePdf(data)
        .then((res) => {
          setshowChatResponse(true);
          console.log("chant response-->", res);
          let chatresponse = res.data?.data;
          console.log("setopen---- 33333", res.data.openai_id);
          if (res.data.openai_id) {
            setOpenaiId(res.data.openai_id);
          }

          setChatResponse(chatresponse);

          // isBookmarked === 0 && bookmarkDisabled
          // setChat(prevObj=>[...prevObj, {"type": "answer", "text": chatresponse}])
          setPageNo(1);
          setShowLoader(false);
        })
        .catch((error) => {
          console.log("error-->", error);
          if (
            error.response?.data?.error ===
            "Your subcription has expired. Please subcribe again."
          ) {
            // globalDataRef.current.setPlanValidityExpired(true)
            // localStorage.setItem("planExpired", true)
            setShowLoader(false);
            // setChat(prevObj=>[...prevObj, {"type": "answer", "text": "Unable to generate response."}])
            Swal.fire(
              "Error",
              "Your subcription has expired. Please subcribe again.",
              "error"
            );
          } else if (
            error.response?.data?.error ==
            "Your free trials has expired. Please subcribe."
          ) {
            // globalDataRef.current.setPlanValidityExpired(true)
            // localStorage.setItem("planExpired", true)
            setShowLoader(false);
            // setChat(prevObj=>[...prevObj, {"type": "answer", "text": "Unable to generate response."}])
            Swal.fire(
              "Error",
              "Your free trial has ended. Kindly upgrade your plan.",
              "error"
            ).then(() => {
              globalDataRef.current.setScrollToPricing(true);
              navigate("/");
            });
          } else if (error?.response?.status === 401) {
            localStorage.clear();
            globalDataRef.current.setSignUpModal(true);
            navigate("/");
          } else {
            setshowChatResponse(false);
            setChatTriggered(false);
            setChat((prevObj) => [
              ...prevObj,
              { type: "answer", text: "Unable to generate response." },
            ]);
            setShowLoader(false);
          }
        });
    } else {
      Swal.fire(
        "Error",
        "Please choose files to submit your questions.",
        "error"
      );
    }
  };

  function getSubscription() {
    let subId = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))?.subscription_plan
      : "";
    openAiService
      .getSubscription(subId)
      .then((res) => {
        if (res.data?.plan.active) {
          if (res.data?.plan.id === DEFAULT_PLAN_ID) {
          } else {
            console.log("res.data.plan--->", res.data.plan);
            globalDataRef.current.setCurrentPlan(res.data.plan);
            localStorage.setItem("plan", JSON.stringify(res.data.plan));
            // here-----
            bookmarkDisabled = false; // as it is not default plan and we have bookmark option in all paid plans
          }
        } else {
          localStorage.removeItem("plan");
        }
      })
      .catch((err) => {
        // console.log("get subscription id err", err)
      });
  }

  const listPlans = () => {
    openAiService
      .listSubscriptions()
      .then((res) => {
        let sortedArray = res.data.data.sort(function (a, b) {
          return parseFloat(a.amount) - parseFloat(b.amount);
        });
        globalDataRef.current.setAllSubPlans(sortedArray);
        // console.log("listSubscriptions response= ", res.data.data);
      })
      .catch((error) => {
        // console.log("error",error);
      });
  };

  function comparePdf(uploadedFile, uploadedFile1) {
    setQueryString("");
    setShowPdfPrompt(false);
    setCompareShowPdfPrompt(false);
    setIsBookmarked(0);
    if (uploadedFile1) {
      let query = `Compare Agreement - ${pdfFile0.name} and ${pdfFile1.name}`;
      setChat((prevObj) => [...prevObj, { type: "question", text: query }]);
    } else {
      let query = "";

      if (query != "") {
        query = query + " & " + ` ${pdfFile.name}`;
      } else {
        query = `${pdfFile.name}`;
      }

      // query = "Highlighted points from-"+query
      // setChat(prevObj=>[...prevObj, {"type": "question", "text": query}])
    }
    // setShowLoader(true)
    // setChatTriggered(true)

    let data = new FormData();

    //  data.append(`pdfFile`, pdfFile)

    data.append("pdfFile", uploadedFile);
    if (true) {
      data.append("openai_id", openaiId);
    }
    if (uploadedFile1) {
      data.append("pdfFile2", uploadedFile1);
    }
    
    data.append("asking_for",memberSelected ?memberSelected : "me")

    setIsLoader(true);
    openAiService
      .comparePdf(data)
      .then((res) => {
        if (uploadedFile) {
          setIsLoader(false);
          console.log(res.data, "=====res");
          setPdfFile("");
          setRecommendedSlots([]);
          // setselectFile(res.data.id)
          // setselectFileName(res.data.name)
          getFiles();
          Swal.fire("Success", "File uploaded successfully", "success");
        } else {
          let documentElm1 = document.getElementById("upload-pdf-1");
          if (documentElm1) {
            documentElm1.value = "";
          }
          let documentElm2 = document.getElementById("upload-pdf-2");
          if (documentElm2) {
            documentElm2.value = "";
          }
          let documentElm3 = document.getElementById("upload-pdf-3");
          if (documentElm3) {
            documentElm3.value = "";
          }
          setshowChatResponse(true);
          let chatresponse = res.data?.data;
          console.log("chatresponse-------", chatResponse);
          console.log("setopen---- 44444", res.data.openai_id);
          setOpenaiId(res.data.openai_id);
          setChatResponse(chatresponse);

          setPdfFile();
          setPdfFile1();
          setCompareShowPdfPrompt(false);
          setShowPdfPrompt(false);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        setPdfFile();
        setPdfFile1();
        setCompareShowPdfPrompt(false);
        setShowPdfPrompt(false);
        // if(globalDataRef.current.currentPlan.id !== PREMIUM_PLAN_ID){
        //   if(error.response.data.error == "Please upgrade your plan."){
        //     globalDataRef.current.setPlanValidityExpired(true)
        //     localStorage.setItem("planExpired", true)
        //     Swal.fire("Error", "Please upgrade your Plan.", "error")
        //   }
        // }
        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        } else {
          setChat((prevObj) => [
            ...prevObj,
            { type: "answer", text: "Unable to generate response." },
          ]);
        }
      });
  }
  const upgradeToPremium = () => {
    openAiService.getPlan(PREMIUM_PLAN_ID).then((res) => {
      let plan = res.data;
      navigate("/card_details", { state: { plan: plan } });
    });
  };

  function getRequestFor() {
    openAiService
      .getRequestFor()
      .then((res) => {
        console.log("result request for-------", res.data);
        setRequestFor(res.data);
      })
      .catch((error) => {
        console.log("userdetails error------", error);
      });
  }

  const checkFile = (allfile) => {
    // if(allfile.files.length)
    console.log(allfile.files);
    for (const key of Object.keys(allfile.files)) {
      console.log(allfile.files[key]);
      let file = allfile.files[key];
      if (file.type === "application/pdf") {
        return { status: true, message: "" };
      } else {
        return { status: false, message: "Upload a valid PDF file." };
      }
    }
  };

  const handleQuerySubmit = (e) => {
    console.log("handlesubmit-----", e);
    try {
      e.preventDefault();
    } catch {}
    if (showPdfPrompt) {
      if (pdfFile) {
        comparePdf(pdfFile);
      } else {
        Swal.fire("Warning", "Please select pdf file!", "warning");
      }
    } else if (showComparePdfPrompt) {
      if (pdfFile0 && pdfFile1) {
        comparePdf(pdfFile0, pdfFile1);
      } else if (!pdfFile0 || !pdfFile1) {
        setCompareShowPdfPrompt(false);
        setShowPdfPrompt(false);
        Swal.fire(
          "Warning",
          "Please select both PDF files to compare",
          "warning"
        );
      }
    } else {
      if (queryString.trim() == "") {
        Swal.fire("Warning", "Enter some query to ask!", "warning");
      } else {
        askQuestion(queryString);
      }
    }
  };

  const startNewChat = () => {
    setOpenaiId();
    setChat([]);
    setIsBookmarked(0);
  };
  // to bookmark/unbookmark any chat history
  const bookmark = () => {
    if (openaiId) {
      // debugger
      openAiService
        .bookmarkHistory(openaiId)
        .then((res) => {
          console.log("resulttttt---", res);
          var bookmarkst = isBookmarked == 0 ? 1 : 0;
          setIsBookmarked(bookmarkst);
          getFiles();
          setShowBoookmark(false);

          // window.location.reload()
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            localStorage.clear();
            globalDataRef.current.setSignUpModal(true);
            navigate("/");
          }
        });
    }
  };

  function ChangeText(text){
    if(text == require("../../assests/images/menu-icon.svg").default){
        setButtonText(require("../../assests/images/close-icon.svg").default)
     }else{
      setButtonText(require("../../assests/images/menu-icon.svg").default)
     }  
}

  function getRecommendedSlots() {
    setIsSeemar(true);
    openAiService
      .getRecommendedSlots(openaiId)
      .then((res) => {
        setIsSeemar(false);
        console.log("getRecommendedSlots", res.data.data);
        setRecommendedSlots(res.data?.data);
        setIsExtra(true);
        setReason(res.data?.user_problem);
        if (
          element.scrollHeight - (element.scrollTop + element.clientHeight) >
          200
        ) {
          setKeepScrollingToBottom(false);
        } else {
          setKeepScrollingToBottom(true);
        }
        setElementHeight(element.scrollHeight);
      })
      .catch((err) => console.log(err));
  }

  const handleOptionChange = (e) => {
    console.log("event---", e.target.value);
    setAppointmentFields({
      ...appointmentFields,
      insurance_type: e.target.value,
    });
  };

  const setAppointmentFor = (e) => {
    console.log("event---", e.target.value);
    setSuggesteduserId();
    setAppointmentFields({
      ...appointmentFields,
      appointment_for: e.target.value,
    });
  };
  const downloadPdf = (openaiId) => {
    if (openaiId) {
      openAiService
        .download_pdf(openaiId)
        .then((res) => {})
        .catch((error) => {
          if (error?.response?.status === 401) {
            localStorage.clear();
            globalDataRef.current.setSignUpModal(true);
            navigate("/");
          }
        });
    }
  };
  // if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
  //   return (
  //     <div className="mircophone-container">
  //       Browser is not Support Speech Recognition.
  //     </div>
  //   );
  // }
  const handleListing = () => {
    setIsListening(true);
    var timer = 60;
    // microphoneRef.current.classList.add("listening");
    SpeechRecognition.startListening({
      continuous: true,
    });
    console.log("=====transcript1234===", transcript);

    interval.current = setInterval(() => {
      timer -= 1;
      setcountHtml(timer + " seconds remaining");
      if (timer < 1) {
        // resetCounter();
        stopHandle();
      }
    }, 1000);

    console.log("=====transcript===", transcript);
  };
  const stopHandle = () => {
    setIsListening(false);
    resetCounter();
    // microphoneRef.current.classList.remove("listening");
    SpeechRecognition.stopListening();
    setQueryString(document.getElementById("input_focused").value);
    // setQueryString(transcript);
    console.log("=====transcript===", transcript);
  };
  const handleReset = () => {
    // setIsListening(false);
    // resetCounter();
    // microphoneRef.current.classList.remove("listening");
    // SpeechRecognition.stopListening();
    setQueryString("");
    resetTranscript();
  };

  function resetCounter() {
    setcountHtml("");
    clearInterval(interval.current);
  }
  const deleteFile = (id) => {
    openAiService
      .delete_pdf(id)
      .then((res) => {
        if (allFiles.length == 1) {
          setFiles();
          setBookmarks([]);
        }
        if (selectfile == id) {
          setChat([]);
          setselectFileName();
          setselectFile();
        }
        // Swal.fire("Success", "File deleted successfully", "succes")
        getFiles();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        }
      });
  };

 

  function trimFileName(filename) {
    var name = filename.split(".");
    console.log("name[0].length - 1",name[0].length - 1)
    name = name[0].substring(0, (name[0].length - 1) > 20 ? 15 :(name[0].length - 1)).trim() + "...";
    return name;
  }

  window.allowModal = (value) => {
    setIsSeemar(value);
    if (
      element.scrollHeight - (element.scrollTop + element.clientHeight) >
      200
    ) {
      setKeepScrollingToBottom(false);
    } else {
      setKeepScrollingToBottom(true);
    }
    setElementHeight(element.scrollHeight);
    if (isUserDobExists != null) {
      getRecommendedSlots();
    } else {
      Swal.fire(
        "Error",
        "To access personalized consultation, kindly ensure that you complete your profile first.",
        "error"
      ).then(() => {
        window.location.href = "/create_profile";
      });
    }
  };

  let formValidated = true;
  // const handleDateChange = (date) => {
  //   if(date < new Date()){
  //     formValidated = false;
  //     setAppointmentFieldsErrors((prevObj) => {
  //       return {
  //         ...prevObj,
  //         selectedDate: true,
  //       };
  //     });
  //   }else{
  //     formValidated = true;
  //     setAppointmentFieldsErrors((prevObj) => {
  //       return {
  //         ...prevObj,
  //         selectedDate: false,
  //       };
  //     });
  //   }
  //   setAppointmentFields({ ...appointmentFields, selectedDate: date })
  // };

  const handleDateChange = (date) => {
    console.log("formated dates", moment(date).format("YYYY-MM-DD"));
    getAvailableSlots(moment(date).format("YYYY-MM-DD"));

    // if (date < new Date()) {
    //   formValidated = false;
    //   setAppointmentFieldsErrors((prevObj) => {
    //     return {
    //       ...prevObj,
    //       selectedDate: true,
    //     };
    //   });
    // } else {
    //   formValidated = true;
    //   setAppointmentFieldsErrors((prevObj) => {
    //     return {
    //       ...prevObj,
    //       selectedDate: false,
    //     };
    //   });
    // }
    setAppointmentFields({ ...appointmentFields, selectedDate: date });
  };

  const isDayEnabled = (date) => {
    console.log("enable days array ", enableDays);
    console.log("enable days ", date.getDay());
    // enableDays[date.getDay()] === enabledDay
    return enableDays.some((enabledDay) => date.getDay() === enabledDay);

    // return date.getDay() !== 1 && date.getDay() !== 4;
  };

  // set calenderdata

  const setCalenderData = (res) => {
    console.log("setCalenderData", new Date(res.date).getDate());
    setAppointmentFields((prevObj) => {
      return {
        ...prevObj,
        selectedDate: new Date(res.date),
      };
    });
    setAppointmentFields((prevObj) => {
      return {
        ...prevObj,
        slot: res.time,
      };
    });
    setAppointmentFields((prevObj) => {
      return {
        ...prevObj,
        phone: phoneNo,
      };
    });

    setAppointmentFields((prevObj) => {
      return {
        ...prevObj,
        reason: reason,
      };
    });
    getAvailableSlots(moment(new Date(res.date)).format("YYYY-MM-DD"));
    setShowAppointmentModal(true);
  };

  function setExtraCalenderData() {
    setAppointmentFields((prevObj) => {
      return {
        ...prevObj,
        phone: phoneNo,
      };
    });
    setAppointmentFields((prevObj) => {
      return {
        ...prevObj,
        reason: reason,
      };
    });
    let first_availble_recomded_slot = recommendedSlots[0];

    if (first_availble_recomded_slot) {
      getAvailableSlots(
        moment(new Date(first_availble_recomded_slot.date)).format("YYYY-MM-DD")
      );
      setAppointmentFields((prevObj) => {
        return {
          ...prevObj,
          selectedDate: new Date(first_availble_recomded_slot.date),
        };
      });
      setAppointmentFields((prevObj) => {
        return {
          ...prevObj,
          slot:first_availble_recomded_slot.time,
        };
      });
    } else {
      getAvailableSlots(moment(new Date()).format("YYYY-MM-DD"));
    }

    setShowAppointmentModal(true);
  }


  const remove_bookmark = (id) => {
    openAiService
      .remove_bookmark(id)
      .then((res) => {
        Swal.fire("Success", "Bookmark removed", "succes");
        getFiles();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.clear();
          globalDataRef.current.setSignUpModal(true);
          navigate("/");
        }
      });
  };

  const handleContactUsSubmit = (e) => {
    e.preventDefault();

    if (appointmentFields.reason.trim() === "") {
      formValidated = false;
      setAppointmentFieldsErrors((obj) => {
        return {
          ...obj,
          reason: true,
        };
      });
    }

    if (appointmentFields.reason.length > 500) {
      formValidated = false;
      setAppointmentFieldsErrors((obj) => {
        return {
          ...obj,
          reason: true,
        };
      });
    }

    // if (appointmentFields.request_for === "") {
    //   formValidated = false;
    //   setAppointmentFieldsErrors((obj) => {
    //     return {
    //       ...obj,
    //       request_for: true,
    //     };
    //   });
    // }
    if (appointmentFields.phone.trim() === "") {
      formValidated = false;
      setAppointmentFieldsErrors((obj) => {
        return {
          ...obj,
          phone: true,
        };
      });
    }
    if (!appointmentFields.phone.length > 12) {
      setAppointmentFieldsErrors((prevObj) => {
        return {
          ...prevObj,
          phone: true,
        };
      });
    }
    if (appointmentFields.insurance_type === "") {
      formValidated = false;
      setAppointmentFieldsErrors((obj) => {
        return {
          ...obj,
          insurance_type: true,
        };
      });
    }
    if (
      appointmentFields.insurance_type === "provider" &&
      appointmentFields.insurance_type_text.trim() === ""
    ) {
      formValidated = false;
      setAppointmentFieldsErrors((obj) => {
        return {
          ...obj,
          insurance_type_text: true,
        };
      });
    }

    if (
      appointmentFields.insurance_type === "provider" &&
      appointmentFields.insurance_type_text.length > 50
    ) {
      formValidated = false;
      setAppointmentFieldsErrors((obj) => {
        return {
          ...obj,
          insurance_type_text: true,
        };
      });
    }

    // if (appointmentFields.appointment_for === "") {
    //   formValidated = false;
    //   setAppointmentFieldsErrors((obj) => {
    //     return {
    //       ...obj,
    //       appointment_for: true,
    //     };
    //   });
    // }

    // if (appointmentFields.appointment_for === "someone" && appointmentFields.patient_name.trim() === "") {

    //   formValidated = false;
    //   setAppointmentFieldsErrors((obj) => {
    //     return {
    //       ...obj,
    //       patient_name: true,
    //     };
    //   });
    // }

    // if(appointmentFields.appointment_for === "someone" && appointmentFields.patient_name > 50){
    //   setAppointmentFieldsErrors((prevObj) => {
    //     return {
    //       ...prevObj,
    //       insurance_type_text: true,
    //     };
    //   });
    // }

    // if (appointmentFields.appointment_for === "someone" && appointmentFields.patient_age === "") {

    //   formValidated = false;
    //   setAppointmentFieldsErrors((obj) => {
    //     return {
    //       ...obj,
    //       patient_age: true,
    //     };
    //   });
    // }

    // if(appointmentFields.appointment_for === "someone" && (appointmentFields.patient_age < 1 || appointmentFields.patient_age > 99)){
    //   setAppointmentFieldsErrors((prevObj) => {
    //     return {
    //       ...prevObj,
    //       patient_age: true,
    //     };
    //   });
    //  }

    //  if(appointmentFields.appointment_for === "someone" && isNaN(appointmentFields.patient_age)){
    //   setAppointmentFieldsErrors((prevObj) => {
    //     return {
    //       ...prevObj,
    //       patient_age: true,
    //     };
    //   });
    //  }

    if (appointmentFields.selectedDate === "") {
      formValidated = false;
      setAppointmentFieldsErrors((obj) => {
        return {
          ...obj,
          selectedDate: true,
        };
      });
    }

    // if (new Date(appointmentFields.selectedDate).getDate() < new Date().getDate()) {
    //   formValidated = false;
    //   setAppointmentFieldsErrors((obj) => {
    //     return {
    //       ...obj,
    //       selectedDate: true,
    //     };
    //   });
    // }

    if (formValidated) {
      // let data = {i
      //   name: appointmentFields.firstName+" "+appointmentFields.lastName,
      //   // last_name: appointmentFields.lastName,
      //   email: appointmentFields.email,
      //   // message: departmentval+":<br></br>"+ appointmentFields.message,
      //   specialist: department"val,
      //   phone: "+" + appointmentFields.phone,
      //   timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      // };

      console.log("without formatted date: ", appointmentFields.selectedDate);
      console.log(
        "Formatted date is here: ",
        moment(appointmentFields.selectedDate).format("YYYY-MM-DDThh:mm")
      );
      let formdata = new FormData();
      formdata.append("report_id", selectfile);
      formdata.append("reason", appointmentFields.reason);
      formdata.append("doctor_type", appointmentFields.request_for);
      formdata.append("insurance_type", appointmentFields.insurance_type);
      formdata.append(
        "insurance_provider",
        appointmentFields.insurance_type_text
      );
      formdata.append("phone", "+" + appointmentFields.phone);
      formdata.append(
        "appointment_time",
        moment(
          new Date(
            moment(appointmentFields.selectedDate).format("YYYY-MM-DD") +
              " " +
              appointmentFields.slot
          )
        ).format("YYYY-MM-DDTHH:mm")
      );
      // formdata.append("appointment_for", appointmentFields.appointment_for);
      formdata.append("appointment_for", "me");

      // let data = {
      //   report_id: selectfile,
      //   reason: appointmentFields.reason,
      //   doctor_type : appointmentFields.request_for,
      //   insurance_type: appointmentFields.insurance_type,
      //   insurance_provider: appointmentFields.insurance_type_text,
      //   phone: appointmentFields.phone,
      //   appointment_time: new Date(appointmentFields.selectedDate),
      //   appointment_for: appointmentFields.appointment_for,

      // };

      // if (suggesteduserId) {
      //   formdata.append("appointed_user_id",suggesteduserId)
      //   // data.appointed_user_id = suggesteduserId;
      // } else {

      //   formdata.append("appointed_user", JSON.stringify({
      //     name: appointmentFields.patient_name,
      //     gender: appointmentFields.patient_gender,
      //     age: appointmentFields.patient_age,
      //   }))
      //   // data.appointed_user = {
      //   //   name: appointmentFields.patient_name,
      //   //   gender: appointmentFields.patient_gender,
      //   //   age: appointmentFields.patient_age,
      //   // };
      // }
      if (appointmentFields.insurance_type === "self") {
        formdata.delete("insurance_provider");
        // delete data.insurance_provider;
      }

      if (appointmentFields.appointment_for === "me") {
        formdata.delete("appointed_user");
        // delete data.appointed_user;
      }

      console.log("sending request", formdata);
      setIsLoader(true);
      openAiService
        .book_appointment(formdata)
        .then((res) => {
          setIsLoader(false);
          setConsine(false);
          setIsCaptchaValue(false);
          setAllowTreatment(false);
          setAllowRisk(false);
          setInfoAccurate(false);
          setAllowHealthData(false);
          setIsChecked(false)
          console.log("contact us api response", res);
          Swal.fire(
            {
              title: "Success",
              text: "Thank you for submitting your request. We are reviewing it and will respond soon!",
              type: "success",
              allowOutsideClick: false,
              // showCancelButton: true,
              // confirmButtonText:
              //   '<a href="/my_appointment">Ok</a>',
            }

          // "Success",
          // "Thank you for submitting your request. We are reviewing it and will respond soon!",
          // "success"
        ).then((result) => {
          setAppointmentFields({
            reason: "",
            request_for: "",
            insurance_type: "self",
            insurance_type_text: "",
            appointment_for: "me",
            patient_gender: "",
            patient_name: "",
            patient_age: "",
            phone: "",
            report_files: [],
          });
          if(result.isConfirmed){
            window.location.href = "/my_appointment"
          }
        });
        })
        .catch((err) => {
          setIsLoader(false);
          Swal.fire("Error", err?.response?.data?.error, "error");
          console.log("contact us api error", err);
        });
    }
  };
  return (
    <>
      <section className="inner-chatbot" id="inner-chatbot-id">
        {isLoader && <Loading />}
        <Container className="position-unset">
          <Row>
            <Col lg={3} md={5}>
              <div
                className={`left-chat-boxes y ${
                  window?.innerWidth < 1199 && onMobileClick
                    ? "mobile-view-bookmark"
                    : ""
                }`}
              >
                <div className="overflow">
                  <div className="chat-history-header">
                    <span>{`Hello${
                      localStorage.getItem("user")
                        ? `, ${capitalizeFirstLetter(
                            JSON.parse(localStorage.getItem("user")).name
                          )}`
                        : ""
                    } 👋`}</span>
                  </div>
                  <div className="add-chat-btn">
                    <Button
                      type="button"
                      variant="unset"
                      onClick={() => globalData.setShowPopupModal(true)}
                    >
                      + Upload New File
                    </Button>
                  </div>
                  <h5 className="text-center">Bookmarks:</h5>
                  <div className="chat-bookmark-area chat-history-left">
                    {
                      bookmarks && bookmarks.length ? (
                        bookmarks.map((file, index) => {
                          let filename = file.file.split("/reports/");
                          return (
                            // <div className="chat-history-area chat-history-left">
                            //   {history.length && !historyLoading ? (
                            <>
                              <div className="bookmark-div d-flex align-items-center">
                                <span
                                  key={file.id}
                                  className={
                                    file.id == selectfile
                                      ? "history-obj-active"
                                      : ""
                                  }
                                  onClick={() => {
                                    setChat([])
                                    selectfileData(file.id, file.file)
                                  }
                                  }
                                >{`${filename[1]
                                  .substring(0, 20)
                                  .trim()}...`}</span>
                                {/* <i class="fa fa-trash ms-2" aria-hidden="true" style={{cursor:"pointer"}}title="Delete" 
                      onClick={() => {if(window.confirm('Are you sure you want to delete?')){remove_bookmark(file.id)}}}
                     ></i> */}
                              </div>
                            </>
                          );
                        })
                      ) : bookmarks.length < 1 ? (
                        "No bookmarks yet :("
                      ) : (
                        <RotatingLines width="20" strokeColor="#111" />
                      )
                      // !historyLoading ?
                      // <p>{localStorage.getItem("plan") === null ? `Upgrade plan to save Bookmarks` : `No bookmarks yet :(`} </p>
                      // :
                      // <RotatingLines width="20" strokeColor="#111" />
                    }
                  </div>

                  <h5 className="text-center">All Files:</h5>
                  <div className="chat-bookmark-area chat-history-left d-flex">
                    {console.log("allFilesallFiles",allFiles)}
                    {allFiles && allFiles.length ? (
                      allFiles.map((file, index) => {
                        // console.log()
                        let filename = file.file.split("/reports/");
                        return (
                          <>
                            <span
                              className={
                                file.id === selectfile
                                  ? "bookmark-div d-flex align-items-center selected"
                                  : "bookmark-div d-flex align-items-center"
                              }
                              style={{cursor:(chatTriggered || showLoader)? "not-allowed":"pointer"}}
                              onClick={() => {
                                if(chatTriggered || showLoader){
                                    return false;
                                }else{
                                  setRecommendedSlots([]);
                                  setIsExtra(false);
                                  setChat([]);
                                  selectfileData(file.id, file.file);
                                  setSelectedFile(file.file);
                                }
                                
                              }}
                              id={"file_" + file.id}
                            >
                              <p
                                key={file.id}
                                className={
                                  file.id == selectfile
                                    ? "history-obj-active m-0 p-0"
                                    : "m-0 p-0"
                                }
                                
                                // className="m-0 p-0"
                              >
                                {trimFileName(filename[1])}
                              </p>
                              <i
                                class="fa fa-trash ms-2"
                                aria-hidden="true"
                                style={{ cursor: "pointer" }}
                                title="Delete"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Warning",
                                    text: "Are you sure you want to delete this chat history?",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Delete",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      deleteFile(file.id);
                                    }
                                  });
                                  // if(window.confirm('Are you sure you want to delete this chat history?')){deleteFile(file.id)}
                                }}
                              ></i>
                            </span>
                          </>
                        );
                      })
                    ) : !allFiles || allFiles.length == 0 ? (
                      <p>{"No file uploaded :("} </p>
                    ) : (
                      <RotatingLines width="20" strokeColor="#111" />
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={9} md={7}>
              <div className="mid-chat-box">
                <div className="chatbox-header">
                  {selectfile ? (
                    //  let filearr =  selectfileName.split("/reports/")
                    <h3>
                      Q&A file{" "}
                      {selectfileName
                        ? selectfileName.split("/reports/")[1].split(".pdf")[0]
                        : null}
                      ✍️
                    </h3>
                  ) : (
                    <h3>AI Health Assistant Clinical Insights ✍️</h3>
                  )}
                   {/* <div className="active-tabs">
                    <Button className={isActive == "me" ? "":"someone-text"} onClick={() => {
                      setMemberSelected("")
                      setIsActive("me")}} >Asking for me</Button>
                    <Button className={isActive == "someone" ? "":"someone-text"} onClick={() => {
                      setIsActive("someone")
                      handleShowAdd()}} >Someone</Button>
                  </div> */}
                  {chat.length > 0 ?
                  <span>
                  {JSON.parse(localStorage.getItem("user"))
                    ?.subscription_plan != "null" &&
                    openaiId &&
                    isBookmarked === 1 &&
                    bookmarkDisabled ? (
                    <img
                      onClick={() => {
                        if(showLoader ||
                          showChatResponse ||
                          chatTriggered){
                            return false
                          }else{
                            bookmark()
                          }
                        }}
                      className={
                        showLoader ||
                          showChatResponse ||
                          chatTriggered ? "disableBtn bookmark-img" :
                          "bookmark-img"}
                      src={require("../../assests/images/bookmark.png")}
                      alt="bookmark"
                    ></img>
                  ) : // <></>
                    openaiId && isBookmarked === 0 && bookmarkDisabled ? (
                      <img
                        onClick={() => {
                          if(showLoader ||
                            showChatResponse ||
                            chatTriggered){
                              return false
                            }else{
                         
                              bookmark()
                            }
                          }}
                        className={
                          showLoader ||
                            showChatResponse ||
                            chatTriggered ? "disableBtn bookmark-img" :
                            "bookmark-img"}
                        src={require("../../assests/images/bookmark-white.png")}
                        alt="bookmark"
                      ></img>
                    ) : null}
                </span>
                  :""}
                  
                </div>
                <div id="chatbox-body-id" className="chatbox-body">
                  {openaiId ? null : (
                    <>
                      {selectfile ? (
                        <></>
                      ) : (
                        <div className="left-chat-box">
                          <p className="prompt-heading">
                            Kindly select a file from your list of files to ask
                            questions.
                          </p>
                        </div>
                      )}
                      {/* {sampleQuestions.map((que, index)=>{
                      return(
                        <div key={index} className="left-chat-box ">
                          <p style={{'cursor': 'pointer'}} onClick={()=>{setQueryString(que[1])}}>
                          {que.join(" ")}
                          </p>
                        </div>
                      )
                    })} */}
                    </>
                  )}
                  {console.log("chat---", chat)}
                  {chat.length > 0
                    ? chat.map((chatObj, index) => {
                        var replaces = chatObj.text;
                        if (chatObj.type === "answer") {
                          if (
                            chatObj.text.includes("Book an appointment") ||
                            chatObj.text.includes("booking an appointment") ||
                            chatObj.text.includes("book an appointment") ||
                            chatObj.text.includes("Booking an appointment")
                          ) {
                            console.log("tryeeee");
                            replaces = chatObj.text.replace(
                              /Book an appointment with us|book an appointment with us|booking an appointment with us|Booking an appointment with us|Book an appointment|book an appointment|booking an appointment|Booking an appointment/gi,
                              `<a href="javascript:;" id="set-appointment" onClick={allowModal(true)} style={{cursor:"pointer"}} >Book an Appointment</a>`
                            );
                          }
                          return (
                            <div key={index} className="left-chat-box">
                              {console.log("chatObj-->", chatObj)}
                              <p>
                                <div
                                  dangerouslySetInnerHTML={{ __html: replaces }}
                                ></div>{" "}
                              </p>
                              <br></br>{" "}
                              {/* <p
                              style={{ cursor: "pointer" }}
                              className="sub-para1"
                            >
                              For Personalized consultation{" "}
                              <b
                                onClick={() => {
                                  if (isUserDobExists != null) {
                                    return setShowAppointmentModal(
                                      true
                                    );
                                  } else {
                                    Swal.fire(
                                      "Error",
                                      "To access personalized consultation, kindly ensure that you complete your profile first.",
                                      "error"
                                    ).then(() => {
                                      window.location.href =
                                        "/create_profile";
                                    });
                                  }
                                }}
                              >
                                Click here
                              </b>
                            </p> */}
                            </div>
                          );
                        } else {
                          return (
                            <div key={index} className="right-chat-box">
                              <p>{chatObj.text}</p>
                            </div>
                          );
                        }
                      })
                    : null}

                  {!isSeemar &&
                  recommendedSlots &&
                  recommendedSlots.length > 0 ? (
                    <p className="select-slot">
                      <b>Select your preferred slot:</b>
                    </p>
                  ) : (
                    ""
                  )}

                  <div className="clock-main">
                    {isSeemar && (
                      <>
                        {[1, 2, 3, 4].map(() => (
                          <SkeletonTheme>
                            <span
                              style={{ cursor: "pointer" }}
                              className="tags main"
                            >
                              <h6>
                                <Skeleton count={1} height={40} width={45} />
                              </h6>
                              <p></p>
                            </span>
                          </SkeletonTheme>
                        ))}

                        {/* <SkeletonTheme > */}
                        <span
                        // className="skeleton-theme-view-more "
                        >
                          <h6>
                            <Skeleton
                              style={{ background: "#7269ef" }}
                              count={1}
                              height={25}
                              width={90}
                            />
                          </h6>
                        </span>
                        {/* </SkeletonTheme> */}
                      </>
                    )}

                    {!isSeemar &&
                    recommendedSlots &&
                    recommendedSlots.length > 0
                      ? recommendedSlots.map((res) => {
                          return (
                            <span
                              style={{ cursor: "pointer" }}
                              className="tags main"
                              onClick={() => {
                                setCalenderData(res);
                              }}
                            >
                              <h6>{CommonFunction.returnFormattedDay(res.date)}</h6>
                              <p>
                                <i class="fa fa-clock-o" aria-hidden="true"></i>
                                {removeSecondFromString(res.time)}
                              </p>
                            </span>
                          );
                        })
                      : ""}
                    {!isSeemar && isExtra && (
                      <span
                        style={{ cursor: "pointer" }}
                        className="view-more"
                        onClick={() => {
                          setExtraCalenderData();
                        }}
                      >
                        <h6>{"Pick another slot"}</h6>
                      </span>
                    )}
                  </div>
                  {showLoader && (
                    <div className="left-chat-box">
                      <div class="typing-loader"></div>
                    </div>
                  )}
                  {showChatResponse && (
                    <div className="left-chat-box">
                      <p id="chat-response-id"></p>
                      <br></br>{" "}
                      {/* <p style={{ cursor: "pointer" }} className="sub-para1">
                      For Personalized consultation{" "}
                      <b
                        onClick={() => {
                          if (isUserDobExists != null) {
                            return setShowAppointmentModal(true);
                          } else {
                            Swal.fire(
                              "Error",
                              "To access personalized consultation, kindly ensure that you complete your profile first.",
                              "error"
                            ).then(() => {
                              window.location.href = "/create_profile";
                            });
                          }
                        }}
                      >
                        Click here
                      </b>
                    </p> */}
                    </div>
                  )}
                  {/* {chat.length > 0 ?
                    <p className="sub-para1">For Personalized consultation <b  onClick={() => 
                      globalData.setShowContactUsModal(true)
                  }style={{cursor:"pointer"}} >Click here</b></p>:null} */}
                </div>
                {/* {globalDataRef.current.planValidityExpired
                ?
                <div className="upgrade-to-pro-style" onClick={()=>{
                  globalDataRef.current.setScrollToPricing(true)
                  navigate("/")
                }}>Upgrade to pro</div>
                : */}
                <div className="chatbox-footer">
                  {/* <Button
                    variant="primary"
                    onClick={handleShow}
                    className="prompt"
                  >
                    Prompt Library +
                  </Button> */}
                  <form onSubmit={(e) => handleQuerySubmit(e)}>
                    <textarea
                      id="input_focused"
                      class="noSelect"
                      ref={input_focused}
                      // disabled={showLoader || showComparePdfPrompt || showPdfPrompt || showChatResponse || chatTriggered}
                      // type="text"
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          if (
                            showLoader ||
                            showChatResponse ||
                            chatTriggered ||
                            isListening
                          ) {
                            return false;
                          } else {
                            if (e.keyCode === 13 && !e.shiftKey) {
                              handleQuerySubmit(e);
                            }
                          }
                        }
                      }}
                      placeholder="Ask something about your health!"
                      value={queryString}
                      onChange={(e) => {
                        if(isListening){
                          return false;
                        }else{
                        setQueryString(e.target.value);
                        }
                      }}
                    ></textarea>

                    {console.log(
                      "navigator.userAgent",
                      window.navigator?.brave?.isBrave
                    )}
                    <div className="send-record-btn">
                      <button
                        type="submit"
                        style={{ cursor: "pointer" }}
                        disabled={
                          showLoader ||
                          showChatResponse ||
                          chatTriggered ||
                          queryString.trim().length === 0 ||
                          isListening
                        }
                        className={
                          showLoader ||
                          showChatResponse ||
                          chatTriggered ||
                          queryString.trim().length === 0 ||
                          isListening ||
                          (queryString !== "" && isListening)
                            ? "send-btn-disabled submit submit-light"
                            : "submit submit-light"
                        }
                      >
                        <i class="fa fa-paper-plane" aria-hidden="true"></i>
                      </button>

                      {navigator.userAgent.includes("Chrome") &&
                      !window.navigator?.brave?.isBrave ? (
                        <div className="microphone-section-area">
                          {!isListening && (
                            <div
                              className={
                                showLoader ||
                                showChatResponse ||
                                chatTriggered ||
                                isListening
                                  ? "disableBtn microphone-icon-container submit"
                                  : "microphone-icon-container submit"
                              }
                              // ref={microphoneRef}
                              style={{ cursor: "pointer" }}
                              // disabled ={}
                              onClick={
                                showLoader ||
                                showChatResponse ||
                                chatTriggered ||
                                isListening
                                  ? () => {
                                      return false;
                                    }
                                  : () => {
                                      handleListing();
                                      document
                                        .getElementById("input_focused")
                                        .focus();
                                    }
                              }
                            >
                              <i
                                class="fa fa-microphone microphone-icon"
                                aria-hidden="true"
                              ></i>

                              {/* <img src={microPhoneIcon} className="microphone-icon" /> */}
                            </div>
                          )}
                          {isListening && (
                            <div
                              className="microphone-icon-container submit"
                              onClick={stopHandle}
                              style={{ cursor: "pointer" }}
                            >
                              <i
                                class="fa fa-stop-circle"
                                aria-hidden="true"
                              ></i>
                            </div>
                            // <button
                            //   className="microphone-stop btn"

                            // >
                            //   Stop
                            // </button>
                          )}
                          <div>
                            <p id="counter">{counterHtml}</p>
                          </div>
                          <div className="microphone-status">
                            {isListening ? (
                              <i class="fa-light fa-waveform-lines"></i>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    {navigator.userAgent.includes("Chrome") &&
                    !window.navigator?.brave?.isBrave
                      ? queryString && (
                          <div
                            className="microphone-icon-container submit"
                            onClick={handleReset}
                            style={{ cursor: "pointer" }}
                          >
                            <i class="fa fa-refresh" aria-hidden="true"></i>
                          </div>
                        )
                      : ""}
                  </form>
                  <p className="sub-para">
                    {Project_Title} may produce inaccurate information about
                    people, places, or facts.
                  </p>
                </div>
                {/* } */}
              </div>
              <div className="chat-animated-button">
                <button
                  onClick={() => {
                    setOnMobileClick(!onMobileClick);
                    ChangeText(buttonText);
                  }}
                  className="animated-btn"
                >
                  <img
                    src={buttonText}
                    alt="img"
                    className="me-1"
                  />
                  {/* {buttonText} */}
                </button>
              </div>
            </Col>

            <Col md={2}></Col>
          </Row>
        </Container>
      </section>
      {/* modal */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Prompt Library</h5>
            <p className="paragraph-txt">
              Choose the prompt that best fits your preference. Upon clicking
              the question, it will be promptly transmitted to the AI Health
              Assistant.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="prompt-box right-chat-box-dropdown dropdown-spacing">
            {prompts.map((que, index) => {
              // console.log(que,"========",que)
              return (
                <Dropdown className="my-2">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {que[0]}
                  </Dropdown.Toggle>

                  {que[1].map((quee, index) => {
                    return (
                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <p
                            onClick={(e) => {
                              let que = e.target.innerHTML;
                              setQueryString(que);
                              setShow(false);
                            }}
                          >
                            {quee}{" "}
                          </p>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    );
                  })}
                </Dropdown>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={upGradePlanPopupModal}
        onHide={() => {
          setUpgradePlanPopupModal(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Enter your Details</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="text-center">
          <div style={{ cursor: "pointer" }}>
            <div className="upgrade-plan">
              <img
                src={require("../../assests/images/upgrade.png")}
                alt="img"
              />
              <p className="premium-plans">
                For access to this feature, upgrade to Premium.
              </p>
              <div
                className="upgrade-to-pro-style w-50"
                onClick={() => {
                  globalDataRef.current.setScrollToPricing(true);
                  globalDataRef.current.setShowPopupModal(false);
                  upgradeToPremium();
                }}
              >
                {" "}
                Upgrate to Premium
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={globalDataRef.current.showPopupModal}
        onHide={() => {
          globalDataRef.current.setShowPopupModal(false);
          setPdfFile("");
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Enter your Details</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="right-chat-boxes right-chat-spacing">
            <div className="left-chat-box">
              <div className="chat-history-header">
                <span> Interact with your Report </span>
              </div>
              <div className="right-chat-box-dropdown chat-agreement-area doc-Chat">
                <div>
                  Upload Document
                  <div>
                    <Form.Group
                      controlId="formFile"
                      className="uplaod-document-area"
                    >
                      <Form.Label>
                        Click to browse or drag
                        <br /> and drop PDF file
                      </Form.Label>
                      <Form.Control
                        type="file"
                        multiple={false}
                        accept="application/pdf"
                        id="upload-pdf-1"
                        style={{ minWidth: "100%" }}
                        onChange={(e) => {
                          let check = checkFile(e.target);
                          if (check.status) {
                            setShowPdfPrompt(true);
                            //   for (const key of Object.keys(e.target.files)) {

                            //     setPdfFile(pdfFile => [...pdfFile, e.target.files[key]]);

                            // }
                            setPdfFile(e.target.files[0]);
                            // setQueryString(`Summarize: ${e.target.files[0].name}`)
                          } else {
                            Swal.fire("Warning", check.message, "warning");
                          }
                        }}
                      />
                    </Form.Group>
                    {/* {pdfFile && pdfFile.length>0 && pdfFile.map(filePdf => { */}
                    {/* console.log("=======filePdf=====",filePdf) */}

                    {pdfFile ? (
                      <p className="sub-para">
                        {pdfFile && pdfFile.name.length > 20
                          ? `${pdfFile.name.substring(20, 0).trim()}...`
                          : pdfFile.name}
                        {","}
                        <i
                          class="fa fa-trash"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setPdfFile("");
                          }}
                          aria-hidden="true"
                        ></i>
                      </p>
                    ) : null}

                    {/* })} */}
                    {/* {pdfFile ? pdfFile.name.length > 20 ? <p className="sub-para">{`${pdfFile.name.substring(20, 0).trim()}...`}</p> : <p className="sub-para">{pdfFile.name}</p> : null} */}
                    <br />

                    <button
                      variant="unset"
                      disabled={showChatResponse || !pdfFile}
                      className={
                        showChatResponse ? "btn-disabled" : "try-ti-buton"
                      }
                      onClick={() => {
                        // if(globalDataRef.current.currentPlan?.id === PREMIUM_PLAN_ID){
                        setShowPdfPrompt(true);
                        globalDataRef.current.setShowPopupModal(false);
                        handleQuerySubmit();
                        // }else{
                        //   setUpgradePlanPopupModal(true)
                        // / }
                      }}
                    >
                      Go
                    </button>
                  </div>
                  {/* </Dropdown.Item> */}
                </div>
              </div>
              {/* <div className="right-chat-box-dropdown chat-agreement-area">
              <Dropdown autoClose={false}>
                <Dropdown.Toggle variant="success" id="dropdown-basic-id">
                  Compare Agreements
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    Upload Documents
                    <div>
                    <Form.Group
                        controlId="formFile"
                        className="uplaod-document-area"
                      >
                        <Form.Label onClick={(e) => e.preventDefault()}>
                          Click to browse or drag
                          <br /> and drop PDF file
                        </Form.Label>
                        <Form.Control type="file" accept="application/pdf" id="upload-pdf-2" style={{'minWidth': '100%'}}
                          multiple={false}
                          onChange={(e)=>{
                            let check = checkFile(e.target)
                            if(check.status){
                              setPdfFile0(
                                e.target.files[0]
                                )
                              // let query
                              // if(pdfFile1){
                              //   query = `Compare: ${e.target.files[0].name} and ${pdfFile1.name}`
                              // }else{
                              //   query = `Compare: ${e.target.files[0].name}`
                              // }
                              // setQueryString(query)
                              setTimeout(()=>{
                                setCompareShowPdfPrompt(true)
                              }, 300)
                            } else {
                              Swal.fire("Warning", check.message, "warning")
                            }
                          }}
                          />
                          <br/>
                          {pdfFile0 ? pdfFile0.name.length > 20 ? <p className="sub-para">{`${pdfFile0.name.substring(20, 0).trim()}...`}</p> : <p className="sub-para">{pdfFile0.name}</p> : null}
                      </Form.Group>
                      <br/>
                      <Form.Group
                        controlId="formFile"
                        className="uplaod-document-area"
                      >
                        <Form.Label>
                          Click to browse or drag
                          <br /> and drop PDF file
                        </Form.Label>
                        <Form.Control type="file" accept="application/pdf" id="upload-pdf-3" style={{'minWidth': '100%'}}
                          multiple={false}
                          onChange={(e)=>{
                            let check = checkFile(e.target)
                            if(check.status){
                              setPdfFile1(e.target.files[0])
                             
                              setTimeout(()=>{
                                setCompareShowPdfPrompt(true)
                              }, 300)
                              } else {
                              Swal.fire("Warning", check.message, "warning")
                            }
                          }}
                          />
                          <br/>
                          {pdfFile1 ? pdfFile1.name.length > 20 ? <p className="sub-para">{`${pdfFile1.name.substring(20, 0).trim()}...`}</p> : <p className="sub-para">{pdfFile1.name}</p> : null}
                      </Form.Group>
                      <br/>
                    
                         <button className={showChatResponse || (!pdfFile0 && !pdfFile1) ? "btn-disabled" : ""} disabled={showChatResponse || (!pdfFile0 && !pdfFile1)} onClick={()=>{
                        if(globalDataRef.current.currentPlan?.id === PREMIUM_PLAN_ID){
                          setCompareShowPdfPrompt(true)
                          globalDataRef.current.setShowPopupModal(false);
                          handleQuerySubmit()
                        }else{
                          setUpgradePlanPopupModal(true)
                        }
                        }}>Go</button>
                    </div>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
              {/* <div className="right-chat-box-dropdown chat-agreement-area">
              <Dropdown autoClose={false}>
                <Dropdown.Toggle variant="success" id="dropdown-basic-id">
                 Create Agreement
                </Dropdown.Toggle>
                <Dropdown.Menu>
                Complete the form.
                 Any field can be skipped.
                    <div>
                    <br/>
                    <Form.Group controlId="formFile">
                        <Form.Label onClick={(e) => e.preventDefault()}>
                        Identify parties
                        </Form.Label>
                        <Form.Control type="text"  placeholder="Me and my friend Saurish" style={{'minWidth': '100%'}}
                        onChange={(e)=>{ 
                           setcontractField((prevObj) => {
                            return {
                              ...prevObj,
                              identifyPartie: e.target.value,
                            };
                          });   
                        }} />
                       </Form.Group>
                     
                      <Form.Group controlId="formFile">
                        <Form.Label onClick={(e) => e.preventDefault()}>
                        Define purpose
                        </Form.Label>
                        <Form.Control type="text"  placeholder="To share my toy with Saurish" style={{'minWidth': '100%'}}
                        onChange={(e)=>{
                          setcontractField((prevObj) => {
                            return {
                              ...prevObj,
                              definePurpose: e.target.value,
                            };
                          });  
                         }} />
                      </Form.Group>
                     
                      <Form.Group controlId="formFile"  >
                        <Form.Label onClick={(e) => e.preventDefault()}>
                        Outline obligations
                        </Form.Label>
                        <Form.Control type="text"  placeholder="Take turns playing with toy" style={{'minWidth': '100%'}}
                        onChange={(e)=>{
                          setcontractField((prevObj) => {
                            return {
                              ...prevObj,
                              outlineObligation: e.target.value,
                            };
                          });  
                         }} />
                      </Form.Group>
                    
                      <Form.Group controlId="formFile">
                        <Form.Label onClick={(e) => e.preventDefault()}>
                        Determine consequences
                        </Form.Label>
                        <Form.Control type="text"  placeholder="Saurish gives snack if no play" style={{'minWidth': '100%'}}
                        onChange={(e)=>{ setcontractField((prevObj) => {
                          return {
                            ...prevObj,
                            determineConsequences: e.target.value,
                          };
                        });  }} />
                       </Form.Group>
                    
                      <Form.Group controlId="formFile" >
                        <Form.Label onClick={(e) => e.preventDefault()}>
                        Specify dates
                        </Form.Label>
                        <Form.Control type="text"  placeholder="Starts today, ends in a week" style={{'minWidth': '100%'}}
                        onChange={(e)=>{
                          setcontractField((prevObj) => {
                            return {
                              ...prevObj,
                              specifyDates: e.target.value,
                            };
                          });  
                         }}
                          />
                      </Form.Group>
                      <Form.Group
                        controlId="formFile"
                          >
                        <Form.Label onClick={(e) => e.preventDefault()}>
                        Identify jurisdiction
                        </Form.Label>
                        <Form.Control type="text"  placeholder="California, USA" style={{'minWidth': '100%'}}
                        onChange={(e)=>{ 
                          setcontractField((prevObj) => {
                            return {
                              ...prevObj,
                              identifyjurisdiction: e.target.value,
                            };
                          });  
                        }}
                          />
                       </Form.Group>
                      <Form.Group
                        controlId="formFile" >
                        <Form.Label onClick={(e) => e.preventDefault()}>
                        Determine signing method
                        </Form.Label>
                        <Form.Control type="text"  placeholder="Online or sign with pen" style={{'minWidth': '100%'}}
                        onChange={(e)=>{
                          setcontractField((prevObj) => {
                            return {
                              ...prevObj,
                              signingMethod: e.target.value,
                            };
                          }); 
                         }}
                          />
                        </Form.Group>
                        <br/>
                       <button className="" onClick={()=>{
                        if(globalDataRef.current.currentPlan?.id === PREMIUM_PLAN_ID){
                          setCompareShowPdfPrompt(true)
                          globalDataRef.current.setShowPopupModal(false);
                          createAgreement()
                        }else{
                          setUpgradePlanPopupModal(true)
                        }
                        }}>Go</button>
                    </div>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal show={showAdd} onHide={handleCloseAdd} centered>
        <Modal.Header closeButton>
          <Modal.Title>Family Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="family-boxes">
            {members.length > 0 ?
            members.map((res,index) => {
              console.log("memememberer", res)
              return (<div className="family-inner-box">
              <div>
                <img
                  src={res.image ? (baseUrl+res.image) :require("../../assests/images/adam-smith.png")}
                  alt="img"
                  className="me-1"
                />
                <h5>{res.name}</h5>
              </div>
              <input
                type="checkbox"
                class="rounded-checkbox"
                id={"member_" + i}
                name="member_check"
                value={res.id}
                onChange={
                  (e) =>{setMemberSelected(e.target.value)}
                }
                checked={memberSelected == res.id ? true : ""}
              />
            </div>)
            }) 
             : ""}
            
           
          </div>
        </Modal.Body>
      </Modal> */}


      <Modal
        className="modal-area-box appointment-modal"
        show={showAppointmentModal}
        backdrop="static"
        onHide={() => {
          setConsine(false);
          setIsCaptchaValue(false);
          setAppointmentFields({
            reason: "",
            insurance_type: "self",
            insurance_type_text: "",
            appointment_for: "me",
            patient_gender: "",
            patient_name: "",
            patient_age: "",
            phone: "",
            selectedDate: currentDateToSelect,
            request_for: "",
          });
          setAppointmentFieldsErrors({
            reason: false,
            insurance_type: false,
            insurance_type_text: false,
            appointment_for: false,
            patient_gender: false,
            patient_name: false,
            patient_age: false,
            phone: false,
            selectedDate: false,
            request_for: false,
          });
          setShowAppointmentModal(false);
          setConsine(false);
          setIsCaptchaValue(false);
          setIsChecked(false)
          setAppointmentFields({
            reason: "",
            insurance_type: "self",
            insurance_type_text: "",
            appointment_for: "me",
            patient_gender: "",
            patient_name: "",
            patient_age: "",
            phone: "",
            selectedDate: currentDateToSelect,
            request_for: "",
            report_files: [],
            slot: "",
          });
          setAppointmentFieldsErrors({
            reason: false,
            insurance_type: false,
            insurance_type_text: false,
            appointment_for: false,
            patient_gender: false,
            patient_name: false,
            patient_age: false,
            phone: false,
            selectedDate: false,
            request_for: false,
            report_files: false,
            slot: false,
          });
          setAllowTreatment(false);
          setAllowRisk(false);
          setInfoAccurate(false);
          setAllowHealthData(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center p-0">
          <div className="reason-boxes">
            <div className="left-chat-box mt-0">
              {/* <div className="chat-history-header"></div> */}
              <div className="right-chat-box-dropdown m-0">
                <div>
                  <Form onSubmit={handleContactUsSubmit}>
                    {/* <Form.Group controlId="formFile" className="mb-2">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        className={
                          appointmentFieldsErrors.firstName ? "border-red" : ""
                        }
                        placeholder="Enter  Name"
                        value={appointmentFields.firstName}
                        maxLength={30}
                        onChange={(e) => {
                          setAppointmentFields((prevObj) => {
                            return {
                              ...prevObj,
                              firstName: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setAppointmentFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                firstName: true,
                              };
                            });
                          } else {
                            setAppointmentFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                firstName: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group> */}
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Reason For Visit*</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        style={{ minWidth: "100%" }}
                        multiple={false}
                        placeholder="Enter Reason"
                        className={
                          appointmentFieldsErrors.reason ? "border-red" : ""
                        }
                        value={appointmentFields.reason}
                        maxLength={500}
                        onChange={(e) => {
                          setAppointmentFields((prevObj) => {
                            return {
                              ...prevObj,
                              reason: e.target.value,
                            };
                          });
                          if (e.target.value.trim() === "") {
                            setAppointmentFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                reason: true,
                              };
                            });
                          } else if (e.target.value.length > 500) {
                            setAppointmentFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                reason: true,
                              };
                            });
                          } else {
                            setAppointmentFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                reason: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>
                    {/*                     
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Request For*</Form.Label>
                      <select className={
                          appointmentFieldsErrors.request_for ? "border-red" : ""
                        }
                        value={appointmentFields.request_for} onChange={(e) => {
                        setAppointmentFields((prevObj) => {
                          return {
                            ...prevObj,
                            request_for:e.target.value,
                          };
                        })
                        if (e.target.value.trim() === "") {
                          setAppointmentFieldsErrors((prevObj) => {
                            return {
                              ...prevObj,
                              request_for: true,
                            };
                          });
                        } else {
                          setAppointmentFieldsErrors((prevObj) => {
                            return {
                              ...prevObj,
                              request_for: false,
                            };
                          });
                        }
                      }} >
                      <option value="">Select option</option>
                      {
                        requestFor.map((result) => {
                          return <option value={result.id}>{result.name}</option>
                        })

                      }
                      
                    </select>
                      </Form.Group> */}

                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Phone Number*</Form.Label>
                      <PhoneInput
                        country={
                          COUNTRY_CODE ? COUNTRY_CODE.toLocaleLowerCase() : "us"
                        }
                        className={
                          appointmentFieldsErrors.phone
                            ? "border-red phone-input"
                            : "phone-input"
                        }
                        placeholder="Enter phone number"
                        value={appointmentFields.phone}
                        onChange={(e) => {
                          setAppointmentFields((prevObj) => {
                            return {
                              ...prevObj,
                              phone: e,
                            };
                          });
                          if (e.trim() === "") {
                            setAppointmentFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: true,
                              };
                            });
                          } else if (!e.length > 12) {
                            setAppointmentFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: true,
                              };
                            });
                          } else {
                            setAppointmentFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                phone: false,
                              };
                            });
                          }
                        }}
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="formFile"
                      className="mb-3 insurance-type-area "
                    >
                      <Form.Label>Insurance Type*</Form.Label>
                      <input
                        type="radio"
                        value="provider"
                        id="insurance_type"
                        className={
                          appointmentFieldsErrors.insurance_type
                            ? "border-red"
                            : ""
                        }
                        checked={
                          appointmentFields.insurance_type === "provider"
                        }
                        onChange={handleOptionChange}
                      />
                      <p className="text-insu">Insurance Provider</p>
                      <input
                        className="ms-4"
                        type="radio"
                        value="self"
                        id="insurance_type"
                        checked={appointmentFields.insurance_type === "self"}
                        onChange={handleOptionChange}
                      />
                      <p className="text-insu">Self</p>
                    </Form.Group>

                    {appointmentFields.insurance_type === "provider" ? (
                      <div>
                        <Form.Group
                          controlId="formFile"
                          className="mb-3 insurance-type-field "
                        >
                          <input
                            type="text"
                            id="insurance_type_text"
                            placeholder="Insurance Type"
                            maxLength={50}
                            value={appointmentFields?.insurance_type_text}
                            className={
                              appointmentFieldsErrors.insurance_type_text
                                ? "border-red"
                                : ""
                            }
                            onChange={(e) => {
                              setAppointmentFields((prevObj) => {
                                return {
                                  ...prevObj,
                                  insurance_type_text: e.target.value,
                                };
                              });
                              if (e.target.value.length > 50) {
                                setAppointmentFieldsErrors((prevObj) => {
                                  return {
                                    ...prevObj,
                                    insurance_type_text: true,
                                  };
                                });
                              } else if (e.target.value.trim() === "") {
                                setAppointmentFieldsErrors((prevObj) => {
                                  return {
                                    ...prevObj,
                                    insurance_type_text: true,
                                  };
                                });
                              } else {
                                setAppointmentFieldsErrors((prevObj) => {
                                  return {
                                    ...prevObj,
                                    insurance_type_text: false,
                                  };
                                });
                              }
                            }}
                          />
                        </Form.Group>
                        <div className="text-left my-2">
                          {(providers[0] || providers[1]) &&
                          <p className="suggestion-tag">
                            Some Suggested Insurance*
                          </p>
                         }
                          <div className="tags-box">
                            {providers &&
                              providers.map((res, i) => {
                                if (res !== null) {
                                  return (
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className={
                                        index === i ? "tags active" : "tags"
                                      }
                                      onClick={(res) => {
                                        setIndex(i);

                                        console.log(res.target.innerText);
                                        setAppointmentFields((prevObj) => {
                                          return {
                                            ...prevObj,
                                            insurance_type_text:
                                              res.target.innerText,
                                          };
                                        });
                                      }}
                                    >
                                      {res}
                                    </span>
                                  );
                                } else {
                                  return "";
                                }
                              })}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* <Form.Group controlId="formFile" className="mb-3 insurance-type-area">
                      <Form.Label>The appointment is for*</Form.Label>
                      <input 
                        type="radio"
                        value="me"
                        id="appointment_for"
                        checked = {appointmentFields.appointment_for === "me"}
                        onChange={setAppointmentFor}
                        
                      /><p className="text-insu">Me</p>
                      <input
                        type="radio"
                        className="ms-4"
                        value="someone"
                        id="appointment_for"
                        checked = {appointmentFields.appointment_for === "someone"}
                        onChange={setAppointmentFor}
                      /><p className="text-insu">Someone else</p>
                    </Form.Group> */}
                    {/* 
                    {appointmentFields.appointment_for === "someone" ?
                    <Form.Group controlId="formFile" className="mb-3 insurance-type-field">
                        <Form.Label>Patient information*</Form.Label>
                        <div className=" mb-2">
                        <input 
                        type="text"
                        id="patient_name"
                        placeholder="Patient Name"
                        maxLength={50}
                        value={appointmentFields?.patient_name}
                        className={
                          appointmentFieldsErrors.patient_name ? "border-red" : ""
                        }
                        onChange={(e) => {
                          setAppointmentFields((prevObj) => {
                            return {
                              ...prevObj,
                              patient_name:e.target.value,
                            };
                          })
                          if(e.target.value.length > 50){
                            setAppointmentFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                patient_name: true,
                              };
                            });
                          }else{
                            setAppointmentFieldsErrors((prevObj) => {
                              return {
                                ...prevObj,
                                patient_name: false,
                              };
                            });
                          }
                        }
                        }  
                      />
                      <div className="text-left my-2">
                        {userSuggestion && 
                         userSuggestion.map((res) => {
                           if(res !== null){
                            return <span style={{cursor:"pointer"}} className="tags" onClick={() => {
                              console.log('usserereeere',res)
                              console.log('usserereeere',res.age)
                              console.log('usserereeere',res.gender)
                              setSuggesteduserId(res.id);
                              console.log(suggesteduserId);
                              setAppointmentFields((prevObj) => {
                                return {
                                  ...prevObj,
                                  patient_name: res.name,
                                };
                              })

                              setAppointmentFields((prevObj) => {
                                return {
                                  ...prevObj,
                                  patient_age: res.age,
                                };
                              })

                              setAppointmentFields((prevObj) => {
                                return {
                                  ...prevObj,
                                  patient_gender: res.gender,
                                };
                              })

                            }}>{res.name}</span>;
                           }else{
                            return "";
                           } 
                           
                         })
                        }
                    
                    </div>
                      <input 
                        type="number"
                        id="patient_age"
                        placeholder="Patient Age"
                        className={ 
                          appointmentFieldsErrors.patient_age ? "border-red" : ""
                        }
                        value={appointmentFields?.patient_age}
                        onChange={(e) => {
                          setAppointmentFields((prevObj) => {
                            return {
                              ...prevObj,
                              patient_age:parseInt(e.target.value),
                            };
                          })
                          if(e.target.value.trim() !== "" && (e.target.value < 1 || e.target.value > 99)){
                          setAppointmentFieldsErrors((prevObj) => {
                            return {
                              ...prevObj,
                              patient_age: true,
                            };
                          });
                         }else {
                          setAppointmentFieldsErrors((prevObj) => {
                            return {
                              ...prevObj,
                              patient_age: false,
                            };

                          });
                        } 
                         
                        //  if(isNaN(e.target.value)){
                        //   setAppointmentFieldsErrors((prevObj) => {
                        //     return {
                        //       ...prevObj,
                        //       patient_age: true,
                        //     };
                        //   });
                        //  }else {
                        //   setAppointmentFieldsErrors((prevObj) => {
                        //     return {
                        //       ...prevObj,
                        //       patient_age: false,
                        //     };
                        //   });
                        // } 
                        }
                        }  
                          />
                          </div>
                      <select className="insurance-type-field"  value={appointmentFields.patient_gender} onChange={(e) => {
                        setAppointmentFields((prevObj) => {
                          return {
                            ...prevObj,
                            patient_gender:e.target.value,
                          };
                        })
                      }} >
                      <option value="">Select Gender</option>  
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    </Form.Group>:""} */}

                    <Form.Group controlId="formFile" className="mb-4">
                      <Form.Label>Appointment Date*</Form.Label>
                      <div
                        className={
                          appointmentFieldsErrors.selectedDate
                            ? "border-red position-relative"
                            : "position-relative"
                        }
                      >
                        {/* <Datetime
                          value={appointmentFields.selectedDate}
                          initialValue={currentDateToSelect}
                          onChange={handleDateChange}
                          inputProps={{ placeholder: 'Select Date and Time',
                          readOnly:true }}
                          isValidDate={(current) => current.isAfter(minDate)}
                        /> */}

                        <DatePicker
                          selected={appointmentFields.selectedDate}
                          onChange={handleDateChange}
                          filterDate={isDayEnabled} // Use the custom filter function
                          dateFormat="yyyy-MM-dd"
                          minDate={new Date()}
                          className="form-control w-100"
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                      </div>
                      <div className="tags-box-main mt-2">
                        {availableSlots && availableSlots.length > 0
                          ? availableSlots.map((res) => {
                              return res.available ? (
                                <span
                                  style={{ cursor: "pointer" }}
                                  className={
                                    appointmentFields.slot === res.time
                                      ? "tags active"
                                      : "tags"
                                  }
                                  onClick={(e) => {
                                    setAppointmentFieldsErrors((obj) => {
                                      return {
                                        ...obj,
                                        slot: false,
                                      };
                                    });
                                    setAppointmentFields((prevObj) => {
                                      return {
                                        ...prevObj,
                                        slot: res.time,
                                      };
                                    });
                                  }}
                                >
                                  <i
                                    class="fa fa-clock-o"
                                    aria-hidden="true"
                                  ></i>
                                  {removeSecondFromString(res.time)}
                                </span>
                              ) : (
                                <span
                                  style={{ cursor: "pointer" }}
                                  className={"tag-disables tags"}
                                >
                                  <i
                                    class="fa fa-clock-o"
                                    aria-hidden="true"
                                  ></i>
                                  {removeSecondFromString(res.time)}
                                </span>
                              );
                            })
                          : ""}
                      </div>
                    </Form.Group>
                   
                    <Form.Group className="upper-appointment">
                      <Form.Check
                        inline
                        name="consent"
                        type="checkbox"
                        value={isChecked}
                        onChange={handleChange}
                        checked={isChecked}
                        id={`inline-checkbox-1`}
                      />
                      <span
                        onClick={() => setConsentShow(true)}
                        className="appointment-text"
                      >
                        To book an appointment with us, we kindly request that
                        you provide your consent by{" "}
                        <a className="purple-txt">
                          {" "}
                          agreeing to our terms and conditions{" "}
                        </a>
                      </span>
                    </Form.Group>
                    <br />

                    <Form.Group
                      controlId="formFile"
                      className="mb-2 captcha-size"
                    >
                      <ReCAPTCHA
                        sitekey="6LeKLXAoAAAAAH20G0gfS6AaDgK6zhPjbcNBDeZW"
                        onChange={onChange}
                      />
                    </Form.Group>
                    <br />

                    <button
                      className="try-ti-buton"
                      disabled={
                        isCaptchaValue &&
                        allowTreatment &&
                        allowRisk &&
                        infoAccurate &&
                        allowHealthData &&
                        isChecked
                          ? false
                          : true
                      }
                      type="submit"
                    >
                      Submit
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="success-box appointment-modal"
        show={consentShow}
        onHide={hideConsent}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div key={`inline-checkbox`} className="mb-2 d-flex">
            <Form.Check
              inline
              name="group1"
              type="checkbox"
              onChange={() => setAllowTreatment(allowTreatment ? false : true)}
              checked={allowTreatment}
              id={`inline-checkbox-1`}
            />
            <Form.Label>
              I authorize [AI Health Assistant] to perform my treatment or necessary
              procedure to me/ or my (for parent/Guardian) dependent.
            </Form.Label>
          </div>
          <div key={`inline-checkbox`} className="mb-2 d-flex">
            <Form.Check
              inline
              name="group1"
              type="checkbox"
              onChange={() => setAllowRisk(allowRisk ? false : true)}
              id={`inline-checkbox-1`}
              checked={allowRisk}
            />
            <Form.Label>
              I understand the risk and complications if I do not follow the
              instructions given to me after the procedure which involves
              post-treatment and follow-ups.
            </Form.Label>
          </div>
          <div key={`inline-checkbox`} className="mb-2 d-flex">
            <Form.Check
              inline
              name="group1"
              type="checkbox"
              onChange={() => setInfoAccurate(infoAccurate ? false : true)}
              id={`inline-checkbox-1`}
              checked={infoAccurate}
            />
            <Form.Label>
              I acknowledge that all information I provided in this form is true
              and accurate.
            </Form.Label>
          </div>
          <div key={`inline-checkbox`} className="mb-3 d-flex">
            <Form.Check
              inline
              name="group1"
              type="checkbox"
              onChange={() =>
                setAllowHealthData(allowHealthData ? false : true)
              }
              id={`inline-checkbox-1`}
              checked={allowHealthData}
            />
            <Form.Label>
              I authorize [AI Health Assistant] to access my health related data and
              records my treatment.
            </Form.Label>
          </div>
          <div className="text-center">
            <button
              className="try-ti-buton"
              disabled={
                allowTreatment && allowRisk && infoAccurate && allowHealthData
                  ? false
                  : true
              }
              onClick={() => setConsentShow(false)}
            >
              Agree
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Chat;
